<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Satuan</label>
              <InputText
                v-model="form.sat"
                :class="{ 'p-invalid': v$.form.sat.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
                maxlength="10"
              />
              <small
                v-if="
                  (v$.form.sat.$invalid && submitted) ||
                  v$.form.sat.$pending.$response
                "
                class="p-error"
                >{{ v$.form.sat.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-12">
              <label>Nama</label>
              <InputText
                v-model="form.name"
                :class="{ 'p-invalid': v$.form.name.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
                maxlength="50"
              />
              <small
                v-if="
                  (v$.form.name.$invalid && submitted) ||
                  v$.form.name.$pending.$response
                "
                class="p-error"
                >{{ v$.form.name.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-12">
              <label>QTY</label>
              <InputText
                v-model="form.qty"
                :class="{ 'p-invalid': v$.form.qty.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.qty.$invalid && submitted) ||
                  v$.form.qty.$pending.$response
                "
                class="p-error"
              >
                <span v-if="v$.form.qty.required.$invalid">
                  {{ v$.form.qty.required.$message }}
                </span>
                <span v-else> {{ v$.form.qty.numeric.$message }}</span>
              </small>
            </div>
            <div class="field col-12 md:col-3">
              <label></label>
              <input
                :checked="form.active"
                type="checkbox"
                id="active"
                @click="OnCheckClick"
              /><label for="active">Aktif</label>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, numeric, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
    OnCheckClick() {
      // for casting active checkbox checked value to int
      this.form.active = this.form.active == 0 ? 1 : 0
    },
  },
  validations() {
    return {
      form: {
        sat: {
          required: helpers.withMessage('Satuan harus diisi.', required),
        },
        name: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
        qty: {
          required: helpers.withMessage('QTY harus diisi.', required),
          numeric: helpers.withMessage(
            'QTY harus harus diisi dengan angka.',
            numeric
          ),
        },
      },
    }
  },
}
</script>
