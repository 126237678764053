<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Satuan</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Data Satuan</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Satuan"
          class="mr-2"
          @click="onAddData"
        />
      </div>
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
        @sort="onSort($event)"
      >
        <template #columns>
          <Column
            field="sat"
            header="Kode"
            style="min-width: 15rem"
            sortable
            :showFilterMenu="false"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="name"
            header="Satuan"
            style="min-width: 15rem"
            sortable
            :showFilterMenu="false"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column field="qty" header="qty" style="min-width: 15rem"> </Column>
          <Column field="active" header="Aktif" style="min-width: 6rem">
            <template #body="slotProps">
              <Tag
                v-if="slotProps.data.active"
                severity="success"
                value="Aktif"
              ></Tag>
              <Tag
                v-else
                :style="{ background: 'grey' }"
                value="Tidak Aktif"
              ></Tag>
            </template>
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit Satuan' : 'Tambah Satuan'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
      :maximizable="true"
    >
      <form-satuan
        :item="form"
        :loading="isLoadingSave"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Hapus Satuan"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Satuan <strong>{{ form.name }}</strong> akan dihapus. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteSatuan"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import SatuanService from '@/services/SatuanService'
import FormSatuan from '@/components/master/FormSatuan'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'
import Tag from 'primevue/tag'

export default {
  name: 'Satuan',
  components: {
    FormSatuan,
    FxTable,
    Hotkey,
    Tag,
  },
  data() {
    return {
      satuanService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      form: {},
      satuan: [],
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'id',
        sortOrder: '1',
        filters: null,
      },
      filters: {
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        sat: { value: null, matchMode: FilterMatchMode.EQUALS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  created() {
    this.satuanService = new SatuanService()
  },
  async mounted() {
    this.loadData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.satuanService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data
          this.totalRecords = res.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data satuan', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onSort(e) {
      this.options.value = e
      this.loadData()
    },
    onEditData(data) {
      this.form = Object.assign({}, data)
      this.dialog = true
    },
    onAddData() {
      this.form.id = 0
      this.form.sat = null
      this.form.name = null
      this.form.qty = null
      this.form.active = 0
      this.dialog = true
    },
    onSaveData(data) {
      this.isLoadingSave = true
      if (data.id == 0) {
        // const newData = Object.assign({}, data)
        const newData = {}
        newData.sat = data.sat
        newData.name = data.name
        newData.qty = data.qty
        newData.active = data.active

        this.satuanService
          .add(newData)
          .then((res) => {
            if (res.data.status === 1) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Data satuan',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data satuan', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        const newData = {}
        newData.sat = data.sat
        newData.name = data.name
        newData.qty = data.qty
        newData.active = data.active
        this.satuanService
          .update(data.id, newData)
          .then((res) => {
            if (res.data.status === 1) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Data satuan',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data satuan', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteSatuan() {
      this.satuanService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 1) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data satuan',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Satuan', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
    /* onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    }, */
  },
}
</script>
